'use client'

import type { FC, MouseEvent } from 'react'

import Image from 'next/image'
import { useRouter } from 'next/navigation'

import { UrlObject } from 'url'

import Button from '@components/buttons/button'
import useLocale from '@hooks/client/useLocale'

import cryIcon from '@images/error/cry.svg'

import styles from './error.module.scss'

type ErrorCompProps = {
  content?: string
  callToAction?: string
  redirectUrl?: UrlObject
  title?: string
}

type CustomErrorProps = {
  title?: string
  content?: string
  callToAction?: string
  onClick?: (e: MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => void
}

export function ErrorComponent({ title, content, callToAction, onClick, ...rest }: CustomErrorProps): JSX.Element {
  return (
    <div className={styles.container} {...rest}>
      <div className={styles.contents}>
        <h1>{title}</h1>
        <div className={styles.content}>
          <span>{content}</span>
          <Image src={cryIcon} alt="Cry Face" />
        </div>
        <Button onClick={onClick}>{callToAction}</Button>
      </div>
    </div>
  )
}

const Error: FC<ErrorCompProps> = ({
  callToAction,
  content,
  redirectUrl,
  title = 'Error 5XX',
  ...rest
}: ErrorCompProps): JSX.Element => {
  const router = useRouter()
  const country = useLocale()

  return (
    <ErrorComponent
      title={title}
      content={content || 'Something went wrong on our side. Please click the button below to continue browsing.'}
      callToAction={callToAction || 'Go to homepage'}
      onClick={(): void => {
        if (redirectUrl) {
          router.push(redirectUrl.pathname)
        } else {
          router.push(`/${country}`)
        }
      }}
      {...rest}
    />
  )
}

export default Error
