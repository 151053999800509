'use client'

import { useParams } from 'next/navigation'

import type { Country } from '@types'

export default function useLocale(): Country {
  const params = useParams<{ locale?: Country }>()

  return params?.locale ?? 'us'
}
