'use client'

import { AnchorHTMLAttributes, ButtonHTMLAttributes, MouseEvent, forwardRef, memo, useMemo, useState } from 'react'

import styles from './button.module.scss'
import Link from 'next/link'

type ButtonVariants = 'default' | 'yellow' | 'gray' | 'blue' | 'simple-text' | 'secondary' | 'pink'

type ButtonSize = 'sm' | 'md' | 'lg' | 'full'

type ButtonAndLink = ButtonHTMLAttributes<HTMLButtonElement> & AnchorHTMLAttributes<HTMLAnchorElement>

type ButtonProps = ButtonAndLink & {
  variant?: ButtonVariants
  size?: ButtonSize
  dataCy?: string
  noDefaultStyle?: boolean
}

export type ButtonMouseEvent = MouseEvent<HTMLButtonElement & HTMLAnchorElement>

const Button = forwardRef<HTMLButtonElement & HTMLAnchorElement, ButtonProps>(
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { variant = 'default', children, className, size = 'sm', noDefaultStyle, dataCy, ...htmlAttributes }: ButtonProps,
    ref,
  ): JSX.Element => {
    const [disabled, setDisabled] = useState<boolean>(false)
    const [type, setType] = useState<'submit' | 'reset' | 'button'>(htmlAttributes.type || 'submit')

    // Prevent multiple consecutive clicks
    const onClick = (e: ButtonMouseEvent): void => {
      setDisabled(() => true)
      if (htmlAttributes.onClick && !disabled) {
        htmlAttributes.onClick(e)
      }

      if (!htmlAttributes.type || htmlAttributes.type === 'submit') {
        setTimeout(() => setType('button'), 15)
      }

      setTimeout(() => {
        setType(htmlAttributes.type || 'submit')
        setDisabled(() => false)
      }, 700)
    }

    const internalClassName = useMemo((): string | undefined => {
      let result = className || ''
      if (!noDefaultStyle) {
        result += ` ${styles[variant]} ${styles[`size-${size}`]}`
      }

      if (htmlAttributes.href) {
        result += ` ${styles.block}`
      }
      return result
    }, [className, noDefaultStyle, size, variant, htmlAttributes.href])

    if (htmlAttributes.href) {
      return (
        <Link
          data-testid="link"
          {...htmlAttributes}
          href={htmlAttributes.href}
          onClick={onClick}
          className={internalClassName}
        >
          {children}
        </Link>
      )
    }

    return (
      <button
        ref={ref}
        data-testid="button"
        {...htmlAttributes}
        type={type}
        onClick={onClick}
        className={internalClassName}
      >
        {children}
      </button>
    )
  },
)

Button.displayName = Button.defaultProps?.href ? 'Link' : 'Button'

export default memo(Button)
