'use client'

import type { NextPageWithLayout } from '@types'

import Error from '@components/error'

// Error component must be a client component

export type ErrorPageProps = {
  statusCode?: number
}

const ErrorPage: NextPageWithLayout<ErrorPageProps> = (): JSX.Element => {
  return <Error />
}

export default ErrorPage
